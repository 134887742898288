import React from "react";
import './index.css';
import { useState, useEffect } from 'react';
import { DateTime } from "luxon";

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      Title: "...",
      Definition: "...",
      MeetDT: "dd.MM.yyyy hh:mm",
      PictureLink: `https://miclient.ru/MiClient/api/SRPH/picture/b68ffd3a-19be-40d1-8c42-07223ce71824`
    }
  }

  componentDidMount() {
    fetch('https://miclient.ru/MiClient/api/SRPH/meetup/nearest')
    .then((res) => res.json())
    .then((data) => {
       console.log(data);
       let dt = DateTime.fromISO(data['meetUpMoment'])
       console.log(dt);
       this.setState({
        Title: data['title'],
        Definition: data['definition'],
        MeetDT: `${dt.toFormat('dd.MM.yyyy HH:mm')}`,
        PictureLink: `https://miclient.ru/MiClient/api/SRPH/picture/${data['pictureGUID']}`
       })
    })
    .catch((err) => {
       console.log(err.message);

    });
  }

  render() {
    return(<div className="pony-block">
    <h2 className="pony-title">Тематика сбора ({this.state.MeetDT})</h2>
    <div className="pony-content">
        <div className="pony-img__block"><img className="pony-img__img" src={this.state.PictureLink}></img></div>
        <div className="pony-text">
            <h3>{this.state.Title}</h3>
            <p>{this.state.Definition}</p>
            <div className="pony-link">
                <a href="https://www.youtube.com/channel/UCx1xOc_UuNkr-l6ss0DEYRQ" className="pony-link__block"><img src="https://cdn.iconscout.com/icon/free/png-256/youtube-85-226402.png" alt="" className="pony-link__img"/></a>
                <a href="https://vk.com/sidr_community" className="pony-link__block"><img src="https://img.icons8.com/color/452/vk-com.png" alt="" className="pony-link__img"/></a>
                <a href="https://discord.com/invite/XpPdAPPcXT" className="pony-link__block"><img src="https://img.utdstc.com/icon/a4a/8a6/a4a8a6b50439dd0a020cc3b1b971df3af09ae118578014799abd16ac23be6649:200" alt="" className="pony-link__img"/></a>
                <a href="https://tabun.everypony.ru/profile/Midipon/" className="pony-link__block"><img src="https://files.everypony.ru/tabun/ponies/?1" alt="" className="pony-link__img"/></a>
            </div>
        </div>
    </div>
</div>)
  }
}

export default App;
